<template>
  <div v-if="accessGranted" class="bg-blue-50 text-gray-800">
    <!-- Табы -->
    <div class="flex mb-1 p-5">
      <button
        v-for="(manager, index) in managers"
        :key="manager"
        class="px-4 py-2 mr-2"
        :class="
          activeManager === index
            ? 'bg-blue-500 text-white'
            : 'bg-white text-gray-800'
        "
        @click="activeManager = index"
      >
        {{ manager }}
      </button>
    </div>
    <ManagerScores :calls="calls[managers[activeManager]]" />
    <div class="table">
      <data-table :calls="calls[managers[activeManager]]"></data-table>
    </div>
  </div>
  <div v-if="!accessGranted" style="margin: 10px; color: #000">
    Неверный пароль. Перезагрузите страницу и попробуйте еще раз
  </div>
</template>

<script>
import "./index.css";
import calls from "./data/calls.json";
import DataTable from "./components/DataTable.vue";
import ManagerScores from "./components/ManagerScores.vue";

export default {
  components: { DataTable, ManagerScores },
  data() {
    return {
      // Password application
      accessGranted: process.env.VUE_APP_ACCESS_GRANTED === "true",

      calls,
      activeManager: 0,
      managers: ["Анна Красновская", "Нинель Громова", "Надежда Ермакова"],
    };
  },
  methods: {
    requestAccess() {
      const password = prompt(
        "Пожалуйста, введите пароль для доступа к странице"
      );
      if (password === "DIGITAL2024") {
        this.accessGranted = true;
      } else {
        alert("Неверный пароль. Доступ запрещён.");
      }
    },
  },
  mounted() {
    if (!this.accessGranted) this.requestAccess();
  },
};
</script>