<template>
  <div>
    <table class="min-w-full leading-normal border-collapse">
      <thead>
        <tr>
          <th class="sticky top-0 bg-white px-2.5 py-1.5 border border-black text-xs font-bold text-blue-700 uppercase tracking-wider align-top">
            Дата
          </th>
          <th class="sticky top-0 bg-white px-2.5 py-1.5 border border-black text-xs font-bold text-blue-700 uppercase tracking-wider align-top">
            Ссылка
          </th>
          <th class="sticky top-0 bg-white px-2.5 py-1.5 border border-black text-xs font-bold text-blue-700 uppercase tracking-wider align-top">
            Тема
          </th>
          <th class="sticky top-0 bg-white px-2.5 py-1.5 border border-black text-xs font-bold text-blue-700 uppercase tracking-wider align-top">
            Описание
          </th>
          <th class="sticky top-0 bg-white px-2.5 py-1.5 border border-black text-xs font-bold text-blue-700 uppercase tracking-wider align-top">
            Транскрипт
          </th>
          <th class="sticky top-0 bg-white px-2.5 py-1.5 border border-black text-xs font-bold text-blue-700 uppercase tracking-wider align-top">
            Анализ
          </th>
          <th class="sticky top-0 bg-white px-2.5 py-1.5 border border-black text-xs font-bold text-yellow-700 uppercase tracking-wider align-top">
            Преимущества
          </th>
          <th class="sticky top-0 bg-white px-2.5 py-1.5 border border-black text-xs font-bold text-red-700 uppercase tracking-wider align-top">
            Ошибки
          </th>
          <th class="sticky top-0 bg-white px-2.5 py-1.5 border border-black text-xs font-bold text-green-700 uppercase tracking-wider align-top">
            Улучшения
          </th>
          <th class="sticky top-0 bg-white px-2.5 py-1.5 border border-black text-xs font-bold text-blue-700 uppercase tracking-wider align-top">
            Оценка
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in calls" :key="index">
          <td class="px-2.5 py-2.5 border border-black text-sm align-top">
            {{ getButifDate(item.date) }}
          </td>
          <td class="px-2.5 py-2.5 border border-black text-sm align-top">
            <a :href="item.link" class="text-blue-500 hover:text-blue-700" target="_blank">Перейти</a>
          </td>
          <td class="px-2.5 py-2.5 border border-black text-sm align-top">
            {{ item.theme }}
          </td>
          <td class="px-2.5 py-2.5 border border-black text-sm align-top">
            {{ item.desc }}
          </td>
          <td class="px-2.5 py-2.5 border border-black text-sm align-top">
            {{ item.trans.substring(0, 100) }}...
            <button class="text-blue-500 hover:text-blue-700 text-xs" @click="showModal(item.trans)">
              Показать больше
            </button>
          </td>
          <td class="px-2.5 py-2.5 border border-black text-sm align-top">
            {{ item.analysis }}
          </td>
          <td v-html="replaceNewlinesWithBr(item.advantages)" class="px-2.5 py-2.5 border border-black text-sm text-yellow-700 align-top"></td>
          <td v-html="replaceNewlinesWithBr(item.errors)" class="px-2.5 py-2.5 border border-black text-sm text-red-700 align-top"></td>
          <td v-html="replaceNewlinesWithBr(item.improvements)" class="px-2.5 py-2.5 border border-black text-sm text-green-700 align-top"></td>
          <td class="px-2.5 py-2.5 border border-black text-sm font-bold text-blue-700 align-top">
            {{ item.scores }}%
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Модальное окно -->
    <div v-if="isModalVisible" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center" @click.self="closeModal">
      <div class="bg-white p-4 rounded max-w-lg w-full max-h-full overflow-y-auto">
        <div class="flex justify-end">
          <button @click="closeModal" class="text-red-500 hover:text-red-700 text-4xl">&times;</button>
        </div>
        <div v-html="modalContent"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataTable",
  props: { calls: Array },
  data() {
    return {
      isModalVisible: false,
      modalContent: "",
    };
  },
  methods: {
    showModal(content) {
      this.modalContent = this.replaceNewlinesWithBr(content);
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.modalContent = "";
    },
    getButifDate(date) {
      return date.replace(/\//g, ".");
    },
    replaceNewlinesWithBr(input) {
      return input.replace(/\n/g, "<br>");
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
}

thead.sticky th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
}
</style>
