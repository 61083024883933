<template>
  <div class="container p-4">
    <h2 class="text-2xl font-bold mb-4">
      Средний показатель оценки работы менеджера за каждый месяц
    </h2>
    <table class="min-w-full bg-white border">
      <thead>
        <tr>
          <th class="py-2 px-4 border-b">Месяц</th>
          <th class="py-2 px-4 border-b">Средняя оценка</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(averageScore, month) in getAverageScoresByMonth(calls)"
          :key="month"
        >
          <td class="py-2 px-4 border-b">{{ formatMonth(month) }}</td>
          <td class="py-2 px-4 border-b">{{ averageScore }}%</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "ManagerScores",
  props: {
    managerName: {
      type: String,
      required: true,
    },
    calls: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getAverageScoresByMonth(data) {
      const scoresByMonth = {};

      data.forEach((entry) => {
        // eslint-disable-next-line no-unused-vars
        const [day, month, year] = entry.date.split("/");
        const monthYear = `${month}/${year}`;

        if (!scoresByMonth[monthYear]) {
          scoresByMonth[monthYear] = [];
        }

        scoresByMonth[monthYear].push(entry.scores);
      });

      const averageScoresByMonth = {};

      for (const monthYear in scoresByMonth) {
        const scores = scoresByMonth[monthYear];
        const averageScore = scores.reduce((a, b) => a + b, 0) / scores.length;
        averageScoresByMonth[monthYear] = Math.round(averageScore); // Округление до целого числа
      }

      return averageScoresByMonth;
    },
    formatMonth(monthYear) {
      const [month, year] = monthYear.split("/");
      const months = [
        "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", 
        "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
      ];
      return `${months[month - 1]} ${year}`;
    }
  },
};
</script>

<style scoped>
.table-auto {
  width: 100%;
}
</style>
